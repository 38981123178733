<template>
	<div>
		<v-hover v-slot:default="{ hover }" v-for="(item, i) in items" :key="i">
			<v-row
					class="mx-0 pt-3 cursor-pointer"
					align="center"
					@click="new_val = item.id"
			>
				<v-btn icon x-small class="mx-1" style="padding-top: 1px" :color="new_val === item.id ? 'primary' : hover ? '':'celldark lighten-1'">
					<v-icon size="16">{{ new_val === item.id ? 'mdi-radiobox-marked':'mdi-radiobox-blank' }}</v-icon>
				</v-btn>
				<div class="paragraph transition-cubic" :class="new_val === item.id ? 'primary--text' : hover ? '':'celldark--text text--lighten-1'">{{ item.display_name }}</div>
			</v-row>
		</v-hover>
	</div>
</template>

<script>
	export default {
		name: "RadioSelect",
		props: ['value', 'items'],
		computed: {
			new_val: {
				get(){
					return this.value
				},
				set(val){
					this.$emit('input', val)
				}
			}
		}
	}
</script>

<style scoped>

</style>